import React from 'react';
import _ from 'lodash';
import {graphql, Link} from 'gatsby';

import {Layout} from '../components/index';
import {htmlToReact, withPrefix, getPages} from '../utils';
import BlogPostFooter from '../components/BlogPostFooter';
import Slider from 'react-slick';

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

export default class Post extends React.Component {
    state = {
      maxHeight : 0
    }
    componentDidMount() {
      setTimeout(() => {
        let cards = document.getElementsByClassName("tmp");
        cards = [...cards];
        let maxHeight = 0;
        cards.forEach(element => {
          if(element.offsetHeight > maxHeight){
            maxHeight = element.clientHeight
          }
        });
        this.setState({maxHeight: maxHeight});
      }, 300);
    }
    render() {
        let display_posts = _.orderBy(getPages(this.props.pageContext.pages, '/blog'), 'frontmatter.date', 'desc');
        let recent_posts = display_posts;
        const settings = {
          dots: false,
          infinite: true,
          speed: 1000,
          autoplaySpeed: 3000,
          fadeIn: false,
          autoplay: false,
          pauseOnHover: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        };
        return (
            <Layout {...this.props}>
            <div className="outer">
              <div className="inner-medium">
                <article className="post post-full">
                  <header className="post-header">
                    <h1 className="post-title">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
                    {_.get(this.props, 'pageContext.frontmatter.subtitle', null) && (
                    <div className="post-subtitle">
                      {htmlToReact(_.get(this.props, 'pageContext.frontmatter.subtitle', null))}
                    </div>
                    )}
                  </header>
                  {_.get(this.props, 'pageContext.frontmatter.image', null) && (
                  <div className="post-image">
                    <img src={withPrefix(_.get(this.props, 'pageContext.frontmatter.image', null))} alt={_.get(this.props, 'pageContext.frontmatter.image_alt', null)} />
                  </div>
                  )}
                  {/* <div className="post-content">
                    {htmlToReact(_.get(this.props, 'pageContext.html', null))}
                  </div> */}
                  <div dangerouslySetInnerHTML={ {__html: _.get(this.props, 'pageContext.html', null)} } />
                  <BlogPostFooter {...this.props} page={this.props.pageContext} date_type={'long'} />
                </article>
              </div>
              <div>
                <center><h3 style={{marginBottom: '40px'}}>Other articles</h3></center>
                <Slider {...settings}>
                {_.map(recent_posts, (post, post_idx) => (
                <Link to={withPrefix(_.get(post, 'url', null))} className='no-underline' >
                    <article key={post_idx} className="cell post">
                    <div className="card tmp" style={{minHeight: this.state.maxHeight + 15}}>
                        {_.get(post, 'frontmatter.thumb_image', null) && (
                        <Link className="post-thumbnail" to={withPrefix(_.get(post, 'url', null))}>
                          <img src={withPrefix(_.get(post, 'frontmatter.thumb_image', null))} alt={_.get(post, 'frontmatter.thumb_image_alt', null)} />
                        </Link>
                        )}
                        <div className="post-body">
                        <header className="post-header">
                            <h3 className="post-title"><Link to={withPrefix(_.get(post, 'url', null))}>{_.get(post, 'frontmatter.title', null)}</Link></h3>
                        </header>
                            <div className="post-excerpt">
                            <p>{`${_.get(post, 'frontmatter.excerpt', null)}`} &hellip;</p>
                            </div>
                        <BlogPostFooter {...this.props} page={post} date_type={'short'} />
                        </div>
                    </div>
                    </article>
                </Link>
                ))}
                </Slider>
              </div>
            </div>
            </Layout>
        );
    }
}
